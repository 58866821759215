/* .ViewLogin .Login {
  height: 100vh !important;
} */

#iconContainer {
  right: 10px;
  top: calc(50% - 0.75rem);
}

.ForgotPassword #iconContainer {
  right: 0;
}

.ForgotPassword #iconContainer svg {
  height: 0.75rem;
}

.passwordEnd {
  padding-right: 2rem;
}

.Login .AuthLogin .DirectLogin,
.Login .AuthLogin .DirectLogin:hover {
  color: #000 !important;
  box-shadow: 0 0.1rem 0.519rem #c9c5c5;
}

.orAfterBeforeLine:before {
  right: calc(50% + 1.25rem);
}

.orAfterBeforeLine:after,
.orAfterBeforeLine:before {
  background-color: #000;
  content: "";
  display: block;
  height: 0.094rem;
  position: absolute;
  top: calc(50% - 0.125rem);
  width: 4.688rem;
}

.orAfterBeforeLine:after {
  left: calc(50% + 1.25rem);
}

.formikAdd {
  border-color: #d9d9d9;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
}
