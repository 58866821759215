body {
  background-color: var(--primary);
}

/* Filter Course */
.settingIcon svg {
  transform: rotate(0);
  transition: all 0.5s ease;
}

.settingIcon.isActive svg {
  transform: rotate(90deg);
}

.FilterCourse {
  z-index: 9;
}

.FilterCourse .bg {
  background-color: transparent;
  box-shadow: none;
  transition: all 0.5s ease;
}

.FilterCourse .bg {
  width: 11.875rem;
  border-radius: 0.625rem;
  z-index: 9;
  top: -0.375rem !important;
}

.FilterCourse .bg.c-active {
  box-shadow: 0 0.188rem 0.375rem #12121229;
}

.FilterCourse-Header {
  height: 2.625rem;
  border-bottom: 0.063rem solid transparent;
}

.bg.c-active .FilterCourse-Header {
  border-bottom: 0.063rem solid rgba(112, 112, 112, 0.26);
}

.FilterCourse-Items {
  display: none;
  transition: all 0.5s ease;
  max-height: 15rem;
  overflow-y: scroll;
}

.bg.c-active .FilterCourse-Items {
  display: block;
}

.dark .FilterCourse-Items .NavigationLink:last-child .labelContainer {
  color: rgba(255, 255, 255, 0.5);
}

.light .FilterCourse-Items .NavigationLink:last-child .labelContainer {
  color: rgba(44, 44, 44, 0.5);
}

.FilterCourse-Items .labelContainer {
  margin-left: 0.5rem;
}

.FilterCourse-Items .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
}
/* Filter Course End*/

/* Switch */
.ant-switch-small {
  background-color: #ffffff;
  box-shadow: 0 0.188rem 0.375rem #12121229;
  min-width: 1.75rem !important;
  line-height: 1rem !important;
}

.ant-switch .ant-switch-handle::before {
  background-color: #171b78;
}

.ant-switch.ant-switch-checked {
  background: #121212;
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #fff;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #121212;
}

.ant-switch:hover:not(.ant-switch-disabled) {
  background: #fff;
}

.dark .ant-switch-small {
  background-color: #ffffff;
}

.dark .ant-switch.ant-switch-checked {
  background: #fff;
}

.dark .ant-switch.ant-switch-checked .ant-switch-handle::before {
  background-color: #171b78;
}

.dark .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #fff;
}

.dark .ant-switch:hover:not(.ant-switch-disabled) {
  background: #fff;
}

.antdModal * {
  font-family: "ff_bd";
  font-style: italic;
}

.ant-modal-footer {
  display: none;
}

.ant-modal .ant-modal-close:hover {
  color: var(--navigationBg);
  background-color: transparent;
  text-decoration: none;
}

.AdminIndex .NavigationBar .darklightmode .NavigationLink .labelContainer {
  display: flex;
  align-items: center;
}

/* Learning Course Detail */
.antdModal.CourseDetail {
  background-color: var(--navigationBg);
}

.antdModal.CourseDetail .ant-modal-content {
  padding: 0;
  box-shadow: none;
}

.CourseDetailImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  min-height: 250px;
}

.antdModal.CourseDetail .ant-modal-content {
  background-color: var(--navigationBg);
  border-radius: 1rem;
}

.antdModal.CourseDetail .ant-btn {
  min-height: 50px;
  border: none;
}

.CourseDetail .ant-modal-close-x svg {
  fill: var(--navigationBg) !important;
  height: 1rem;
}

.antdModal.CourseDetail .ant-progress {
  display: flex;
  align-items: center;
}

.antdModal.CourseDetail .ant-progress-text {
  color: var(--color);
  cursor: default;
  font-family: "ff_bd";
  font-size: 1rem;
  margin-left: 1.25rem;
  position: relative;
  top: 0.5rem;
}

.antdModal.CourseDetail .CourseDetailDescription {
  border-radius: 1rem;
}

.CourseDetail .ant-modal-close {
  /* background-color: var(--color); */
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.CourseDetail .ant-modal-close:hover {
  text-decoration: none;
}

/* Custom Drop Down List */
.DropDownList .dropdownInnerText {
  border: 1px solid #ced4da;
}

.dropDownListing {
  width: calc(100%);
  border: 1px solid #ced4da;
  margin-top: -0.75rem;
  z-index: 9;
}

.dropDownListing label {
  text-align: start;
}

.dropDownListing label:last-child {
  padding-bottom: 0 !important;
}

.dropDownRadio {
  width: 0;
  height: 0;
  visibility: hidden;
}

.dropDownListing {
  max-height: 6.25rem;
  overflow-y: scroll;
  background-color: #fff;
}

.dropDownListing label.txt-primary {
  color: #398fe5;
}
/* Custom Drop Down List */
/* Antd Table */
.antdTable * {
  font-family: "ff_md";
}

.ant-table-wrapper .ant-table {
  border-radius: 0.5rem;
}

.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table-thead > tr > td,
.ant-table-wrapper .ant-table-thead > tr > th {
  background: var(--navigationBg);
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-family: "ff_bd" !important;
}

.antdTable.ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.antdTable .ant-table-content::-webkit-scrollbar {
  display: none;
}

.antdTable .ant-pagination .ant-pagination-item {
  color: var(--color);
}

.antdTable .ant-pagination .ant-pagination-next:not(.ant-pagination-disabled) [fill="currentColor"] {
  fill: var(--color) !important;
}

.antdTable .ant-pagination .ant-pagination-prev:not(.ant-pagination-disabled) [fill="currentColor"] {
  fill: var(--color) !important;
}

.antdTable .ant-pagination .ant-pagination-prev.ant-pagination-disabled [fill="currentColor"],
.antdTable .ant-pagination .ant-pagination-next.ant-pagination-disabled [fill="currentColor"] {
  fill: var(--color) !important;
  opacity: 0.5;
}

.antdTable .ant-pagination .ant-pagination-item-active {
  background: var(--navigationLinkActive);
  border-color: var(--navigationLinkActive);
  color: var(--navigationBg);
  font-family: "ff_bd";
}

.antdTable .ant-pagination .ant-pagination-item-active a {
  font-family: "ff_bd";
}

.ant-table-wrapper .ant-table-cell-fix-right {
  background: var(--navigationBg);
}

.modalAntdTable,
.modalAntdTable .ant-table * {
  color: var(--color) !important;
}

.modalAntdTable.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: var(--navigationBg) !important;
}
/* Antd Table */

/* Category Item */
.CategoryItem,
.CategoryAdd {
  height: 3rem;
  box-shadow: 0 0.005rem 0.5rem rgba(255, 255, 255, 0.29);
  border-radius: 3rem;
  min-width: max-content;
}

.light .CategoryItem,
.light .CategoryAdd {
  box-shadow: 0 0.005rem 0.5rem #12121229;
}

.CategoryAdd svg {
  height: 1.2rem;
  margin-right: 0.25rem;
}

.BadgeItem {
  position: relative;
}

.BadgeItem .BadgeItemEdit {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: var(--navigationLinkActive);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  position: absolute;
  top: -0.75rem;
  right: 0.75rem;
}

.BadgeItem .BadgeItemEdit svg {
  height: 0.85rem;
}
/* Category Item */
/* Antd Time Picker */
.antdTimePicker {
  height: 3rem;
  box-shadow: 0 0.005rem 0.5rem rgba(255, 255, 255, 0.29) !important;
  border-radius: 3rem;
  background-color: transparent !important;
  color: var(--color) !important;
  font-family: "ff_bd";
  padding-left: 1rem;
  line-height: normal;
}

.antdTimePicker * {
  color: var(--color) !important;
}

.antdTimePicker.ant-picker .ant-picker-clear {
  background: transparent !important;
}

.antdTimePicker.ant-picker:hover,
.antdTimePicker.ant-picker-focused.ant-picker {
  border-color: var(--color);
}

.antdTimePicker input::placeholder {
  color: var(--color) !important;
}
/* Antd Time Picker */
/* InputNumber */
.InputNumber {
  height: 3rem;
  box-shadow: 0 0.005rem 0.5rem rgba(255, 255, 255, 0.29) !important;
  border-radius: 3rem;
  background-color: transparent !important;
  color: var(--color) !important;
  font-family: "ff_bd";
  min-width: 9rem;
  line-height: 3rem;
  padding-left: 1rem;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-input-number .ant-input-number-handler {
  border-color: transparent;
  background-color: transparent;
}

.ant-input-number .ant-input-number-handler,
.ant-input-number .ant-input-number-handler:hover {
  height: 34% !important;
  flex: unset !important;
}

.ant-input-number:hover {
  border-color: var(--color);
}

.ant-input-number .ant-input-number-handler-wrap {
  background-color: transparent;
  right: 1rem;
  align-items: center;
  justify-content: center;
}

.ant-input-number input {
  font-family: "ff_bd" !important;
  color: var(--color) !important;
}
/* InputNumber */
/* Antd Color Picker */
.antdColorPicker {
  height: 3.125rem;
  min-width: 3.125rem;
}

.antdColorPicker.ant-color-picker-trigger .ant-color-picker-color-block {
  height: 2.5rem;
  min-width: 2.5rem;
}
/* Antd Color Picker */
/* Error Message */
.error-message {
  color: red !important;
  font-family: "ff_rg";
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
/* Error Message */
/* Antd Dragger */
.antdDragger.ant-upload-wrapper .ant-upload-drag {
  color: var(--color);
}

.antdDragger.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: var(--color);
}

.antdDragger .ant-upload-list-picture .ant-upload-list-item-name {
  color: var(--color);
  font-family: "ff_bd";
}

.antdDragger .ant-upload-list-item-actions .ant-upload-list-item-action [fill="currentColor"] {
  fill: var(--color);
}

.ant-upload.ant-upload-select {
  width: 100%;
}

/* .Dragger {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(172, 176, 180, 1)' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 12px;
  min-height: 150px;
} */
/* Antd Dragger */

/* Antd Avatar */
.antdAvatar .ant-upload-select {
  width: 100% !important;
  height: 17.5rem !important;
  border: transparent !important;
  margin-bottom: 0 !important;
}

.antdAvatar .ant-upload-select .ant-upload {
  display: block !important;
}

/* Uploaded List */
.uploadedImageListItems {
  position: relative;
}

.uploadedImageListItems {
  width: 5.375rem;
}

.uploadedImageListItems .fixSize .imageFixing {
  height: 4.25rem;
  width: 100%;
  left: 0;
  padding: 0 0.5rem;
}

.uploadedImageListItems .fixSize img {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.uploadedImageListItems .fixSize .hoverItem {
  background-position: 50% !important;
  background-size: contain !important;
  height: 4.25rem;
  position: relative;
  width: 4.25rem;
}

.uploadedImageListItems .fixSize .hoverEffect {
  margin-top: 1rem;
  transition: all 0.5s ease;
}

.uploadedImageListItems .custom-z-index {
  z-index: 9;
}

.uploadedImageListItems .fixSize:hover .hoverEffect {
  margin-top: 0;
  opacity: 1;
}
/* Radio Button */
.RadioButton {
  transition: all 0.5s ease;
  border: 1px solid var(--navigationBg);
}

.RadioButton.select {
  background-color: rgba(21, 21, 21, 0.5);
  color: var(--navigationLinkActive);
}

/*  Antd Modal Uploader */
/* Antd Upload */
.antdUpload .ant-upload-select {
  width: 100%;
}

.dash-border {
  border: 1px dashed var(--primary);
}

.light .dash-border {
  border: 1px dashed #121212;
}

.antdUpload.uploaded .ant-upload-select {
  display: none;
}

.antdUpload.uploaded .ant-upload-select {
  display: none;
}

.antdUpload.uploaded .ant-upload-list-picture .ant-upload-list-item-error {
  border-color: var(--green) !important;
}

.antdUpload.uploaded .ant-upload-list-picture .ant-upload-list-item-name {
  color: var(--green) !important;
}

.antdUpload.uploaded .ant-tooltip-placement-top {
  display: none !important;
}

.antdUpload.uploaded .ant-upload-list-item-name {
  text-decoration: none !important;
}

.antdUpload.uploaded .ant-upload-list-item-done {
  border-color: var(--green) !important;
}

.antdUpload.uploaded .ant-btn-icon {
  color: var(--red);
}

.antdUpload.uploaded .ant-upload-list-item-image {
  object-fit: cover;
}

.antdUpload .ant-upload-list-picture {
  display: none;
}

.antdUpload.uploaded.customUploader .ant-upload-list-picture {
  display: block;
}

.antdUpload.uploaded.modalPurpose .ant-upload-list-picture {
  display: none;
}
.antdUpload.uploaded.modalPurposeOpen .ant-upload-list-picture {
  display: block;
}

.antdUpload.antdUploadBulk .ant-upload-list-picture {
  display: block;
}

.antdModal .ant-modal-content {
  max-height: min(80vh, 700px);
  overflow-y: scroll;
}

.antdUpload .anticon-delete {
  color: red !important;
}

.antdUpload.uploaded .ant-upload-list-picture .anticon-file {
  font-size: 30px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions.picture .ant-upload-list-item-action {
  background-color: transparent !important;
}

/* Antd Upload */
/* Custom Pagination */
.showCarListPagination .CurrentPage {
  background-color: var(--navigationBg);
  border: 0.063rem solid var(--navigationBg);
  border-radius: 50%;
  align-items: center;
  border: 0.063rem solid transparent;
  display: flex;
  font-size: 0.875rem;
  height: 2.25rem;
  justify-content: center;
  line-height: normal;
  width: 2.25rem;
}

.antdButton,
.antdButton.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: inherit !important;
  border-color: inherit !important;
}

/* Button  */
.dark .AddButton {
}

.light .AddButton,
.light .AddButton * {
  color: #fff !important;
}

.dark .allCourses {
  background-color: #fff;
  color: #121212;
}

.dark .allCourses [stroke="var(--color)"] {
  stroke: #121212;
}

.light .allCourses {
  background-color: #fff;
  color: #747474;
}

.light .allCourses {
  background-color: #fff;
  color: var(--color);
}

.dark .AdminIndex .addBulkbtn {
  background-color: #fff;
  color: #121212;
}

.light .deleteButton button {
  color: #fff !important;
}

.light .emailTemp svg [fill="white"] {
  fill: var(--navigationLinkActive);
}

.light .emailTemp svg [fill="black"] {
  fill: #fff;
}

.light .RadioButton {
  background-color: #121212;
  color: #fff !important;
}

.light .RadioButton.select {
  background-color: var(--navigationLinkActive);
  color: #fff !important;
}

/* Antd Uploader */
.antdUpload.uploaded .ant-btn-icon {
  color: var(--error);
}

.antdModal .antdUpload .ant-upload-list-item .ant-upload-list-item-actions [title="Remove file"] {
  background-color: transparent !important;
}

.antdModal .antdUpload.antdUploadBulk.not-upload .ant-upload-list-picture {
  display: none !important;
}

/* PDF Viewer Css */
#PDF_Display_Section {
  /* margin: 0 auto;
  max-width: 90vw;
  position: relative;
  padding: 1rem 2rem; */
  overflow: auto;
}

#PDF_Display_Section .pdf-container .react-pdf__Page {
  background-color: transparent !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  height: auto !important;
}

/* Antd Select */
.antdSelect .ant-select-selection-placeholder {
  color: #6c757d;
  font-size: 1rem;
}

/* Antd Uploader Custom Id */
:where(.css-antd-uploader).ant-upload-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list::before {
  display: table;
  content: "";
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container {
  transition: opacity 0.3s, height 0.3s;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-container::before {
  display: table;
  width: 0;
  height: 0;
  content: "";
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  flex: none;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 8px;
  line-height: 1.5714285714285714;
  flex: auto;
  transition: all 0.3s;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
  opacity: 0;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm {
  height: 22px;
  border: 0;
  line-height: 1;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions.picture .ant-upload-list-item-action {
  opacity: 1;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm > span {
  transform: scale(1);
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}

:where(.css-antd-uploader).ant-upload-wrapper .ant-upload-list::after {
  display: table;
  clear: both;
  content: "";
}

/* Progressbar */
.ant-progress .ant-progress-text .anticon {
  font-size: 16px;
}

.MyLearningCard .user-group .user-list .ant-avatar,
.ant-avatar-group .ant-avatar {
  border-color: #808080 !important;
}

.antdModal .ant-avatar-group .ant-avatar-lg {
  border-width: 0.125rem !important;
}

.ant-color-picker-inner-content > div {
  display: none !important;
}

.ant-color-picker-inner-content .ant-color-picker-presets {
  display: block !important;
}
