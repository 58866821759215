.AdminLogin .ViewIndex .ViewHeader,
.AdminLogin .ViewIndex .NavigationBar {
  display: none !important;
}

.AdminLogin .ViewIndex .ContentView {
  margin-left: 0;
  padding-top: 0;
}

/* Default */
html,
body {
  height: 100%;
}
.pageTitle {
  font-size: 1.875rem;
  color: var(--color);
  font-family: "ff_bd";
}

.ViewIndex .ContentView {
  position: relative;
  /* top: 5.75rem;
  min-height: calc(100vh - 5.75rem); */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Navigation Bar */
.ViewHeader .logo svg {
  /*height: 1.688rem !important;*/
}

.ViewIndex .NavigationBar {
  width: 17.5rem;
  background-color: var(--navigationBg);
  position: fixed;
  left: 0;
  padding: 1.5rem 0 0.5rem 0;
}

.ViewIndex .NavigationBar {
  padding-top: calc(6rem + 1.5rem);
  height: 100%;
}

.NavigationBar-Sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.NavigationBar-Sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.NavigationBar-Sidenav .sb-sidenav-footer {
  flex-shrink: 0;
}

.NavigationBar .NavigationLink {
  align-items: center;
  color: var(--navigationLink);
  height: 3.625rem;
  text-decoration: none !important;
}

.ViewIndex .NavigationBar .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.NavigationBar .NavigationLink .labelContainer {
  font-family: "ff_md";
  font-size: 1rem;
  margin-left: 0.625rem;
  width: 100%;
  line-height: normal;
}

.NavigationBar .NavigationLink.active .NavIconBG svg [fill="var(--navigationLink)"] {
  fill: var(--navigationLinkActive);
}

.NavigationBar .NavigationLink.active .NavIconBG svg [stroke="var(--navigationLink)"] {
  stroke: var(--navigationLinkActive);
}

.NavigationBar .NavigationLink.active .labelContainer {
  color: var(--navigationLinkActive);
}

.dark .NavigationBar .NavigationLink.active {
  background: rgba(21, 21, 21, 0);
}

.light .NavigationBar .NavigationLink.active {
  background: rgba(236, 243, 253, 0);
}

.NavigationBar .nav-padding,
.NavigationBar .NavigationLink {
  padding: 0 2.313rem;
}

.NavIconBG .border-circle {
  width: 1.625rem;
  height: 1.625rem;
  border-width: 0.25rem;
  border-style: solid;
  border-radius: 50%;
}

.NavIconBG .border-circle.orange {
  border-color: #dec514;
}

.NavIconBG .border-circle.purple {
  border-color: #9783bd;
}

.NavIconBG .border-circle.pink {
  border-color: #c454be;
}

.navbar-group {
  max-height: calc(40vh - 1.875rem);
}

.navbar-group .maxHeight {
  overflow-y: scroll;
  max-height: calc(100% - 3.75rem);
}

.navbar-group > .NavigationLink {
  cursor: pointer;
}

.AddGroup .NavigationLink .labelContainer {
  color: rgba(44, 44, 44, 0.5);
}

.dark .AddGroup .NavigationLink .labelContainer {
  color: rgba(255, 255, 255, 0.5);
}
/* View */
/* Header */
.ViewHeader {
  background-color: var(--navigationBg);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.light .ViewHeader .logo {
  border-color: rgba(229, 229, 229, 0.5);
}

.Search input,
.Search .form-control:focus,
.Search input::placeholder {
  color: var(--color);
}

.ViewHeader .navbar {
  padding: 0 2.313rem;
}

.ViewHeader .navbar .navbar-right {
  gap: 1.25rem;
}

.ViewHeader .user-avatar img {
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  border-radius: 50%;
  min-width: 3rem;
}

.ViewHeader .Overlay {
  background-color: transparent;
  position: fixed;
  content: "";
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  transition: all 0.5s ease;
  display: none;
  z-index: 7;
}

.dark .ViewHeader .Overlay,
.dark .ant-modal-root .ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.59);
}

.light .ViewHeader .Overlay,
.light .ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.75);
}

.ViewHeader .isActive.Overlay {
  display: block;
}

.ViewHeader .Menubar {
  background-color: var(--primary);
  padding: 0 1.781rem;
  position: fixed;
  right: -20rem;
  height: 100%;
  width: 20rem;
  top: 0;
  transition: all 0.5s ease;
  z-index: 9;
  display: flex;
  flex-shrink: 0;
  max-height: 100vh;
}

.ViewHeader .Menubar.isActive {
  right: 0rem;
}

.ViewHeader .Menubar .sb-sidenav {
  display: flex;
  flex-direction: column;
}

.ViewHeader .Menubar-Top-Content {
  overflow-y: auto;
}

.ViewHeader .Menubar .MenubarHeader {
  min-height: 5.75rem;
  position: relative;
  z-index: 9;
}

.ViewHeader .Menubar .Menubar-Items.NavigationBar {
  right: 0 !important;
  position: relative;
  background-color: transparent;
  top: 0;
  width: 100%;
  padding: 0;
  display: flex !important;
  overflow-y: auto;
  height: auto;
  flex-direction: column;
  flex-wrap: nowrap;
}

.ViewHeader .Menubar .Menubar-Items.NavigationBar .NavigationLink {
  padding: 0;
}

.ViewHeader .Menubar .MenubarMode {
  position: relative;
  margin-top: auto;
}

.ViewHeader .Menubar .MenubarMode .NavigationLink {
  color: var(--color);
  width: 100%;
}

.ViewHeader .Menubar .MenubarMode .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.ViewHeader .Menubar .NavigationLink .labelContainer {
  font-family: ff_md;
  font-size: 1rem;
  margin-left: 0.625rem;
  width: 100%;
}

.ViewHeader .Menubar .close-menu {
  position: fixed;
  right: 1rem;
  top: 1rem;
  opacity: 0;
  scale: 0;
  transition: all 0.5s ease;
}

.ViewHeader .Menubar.isActive .close-menu {
  opacity: 1;
  scale: 1;
}
/* Dashboard */
.Dashboard .CoverBackground img {
  height: 15.625rem;
  object-fit: cover;
}

/* Dashboard */
.Dashboard .CoverBackground {
  position: relative;
}

.container-height {
  height: 6.3125rem;
}

.Dashboard .dashboardProfile {
  width: 12.625rem;
  height: 12.625rem !important;
  position: absolute;
  top: -100%;
  left: 1.5rem;
  border-radius: 50%;
}

.Dashboard .DashboardDetail {
  padding: 0 2rem;
}

.CourseCard .bg,
.Archivement .bg {
  background-color: var(--navigationBg);
  border-radius: 1rem;
}

.CourseCard .CourseCard-Icon {
  border-right: 2px solid rgba(242, 242, 242, 1);
}

.dark .CourseCard .CourseCard-Icon {
  border-right: 2px solid rgba(242, 242, 242, 0.39);
}

.CourseCard .CourseCard-Icon .icon {
  display: flex;
  height: 3.438rem;
  width: 3.438rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.CourseCard .CourseCard-Icon .icon svg {
  height: calc(3.438rem - 0.5rem);
}

.viewButton {
  border-radius: 0.375rem;
  background-color: var(--color);
  border: none;
  height: 1.75rem;
  line-height: 1.75rem;
  min-width: 6.875rem;
  text-align: left;
  padding-left: 1.5rem;
  font-style: italic;
  color: #000;
}

.light .viewButton {
  color: var(--navigationBg);
}

.Archivement ul {
  list-style: none;
  display: flex;
  gap: 0.469rem;
  margin-bottom: 0;
}

.Archivement ul img,
.ArchivementMobile-Item .c-active ul img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.DashboardTab {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.MasterData .RadioButtonSelectAnyOne {
  height: 3.75rem !important;
  border-radius: 0.625rem 0.625rem 0.938rem 0.938rem;
  min-width: fit-content;
}

.RadioButtonSelectAnyOne input[type="radio"] {
  width: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.dark .DashboardTab {
  background-color: rgba(41, 54, 65, 0.24);
}

.isTabInActive {
  color: #b4b4b4;
}

.DashboardTab .RadioButtonSelectAnyOne {
  border-top: 3px solid transparent;
}

.light .DashboardTab .RadioButtonSelectAnyOne.isTabActive {
  border-color: #171b78;
}

.DashboardTab .RadioButtonSelectAnyOne .text-none {
  text-transform: none !important;
}

.DashboardTab .isTabActive {
  background: var(--navigationBg);
  border-color: var(--color);
}

.TabSelected {
  background: var(--navigationBg);
  border-radius: 0 0 1rem 1rem;
}

.TabSelected .ant-progress {
  display: flex;
}

.TabSelected .ant-progress-text {
  color: var(--color);
  cursor: default;
  font-family: ff_bd;
  font-size: 1rem;
  margin-left: 1.25rem;
  position: relative;
  top: 0.25rem;
}

.continueButton {
  border-radius: 0.375rem;
  background-color: transparent;
  border: none;
  height: 1.75rem;
  line-height: 1.5rem;
  min-width: 6.875rem;
  text-align: center;
  font-style: italic;
  border: 0.125rem solid var(--color);
  color: var(--color);
  text-transform: uppercase;
}

.light .continueButton {
  border-color: #9a39a7;
  color: #9a39a7;
}

.TabSelected .CoursesCard:last-child .courceDivide > div {
  display: none;
}

.courceDivide > div {
  border-bottom: 0.125rem solid #f2f2f2;
  margin: 1.5rem 0;
}

.dark .courceDivide > div {
  /* border-color: rgba(242, 242, 242, 0.39); */
}

.CoursesCard img {
  height: 7.5rem;
  object-fit: cover;
  border-radius: 1rem;
}

.light .TabSelected .CoursesCard p.f14,
.light .Dashboard .fontSecondary {
  color: #888888;
}

.divider {
  background-color: #d6d6d6;
}
/* My Learning */
.AllCourses {
  border-radius: 0.625rem 0.625rem 0.938rem 0.938rem;
  min-width: fit-content;
  height: 3.75rem;
  background-color: #0190e9;
  padding: 0 0.75rem;
  color: #fff;
}

.MyLearningCard .user-card {
  background-color: #fff;/*var(--learningBg);*/
  border-radius: 0 0 0.625rem 0.625rem;
}

.MyLearningCard .user-card {
  border-radius: 0.625rem;
}

.MyLearningCard .user-card .user-image {
  height: 9.25rem;
  object-fit: cover;
  border-radius: 0.625rem 0.625rem 0 0;
}

.MyLearningCard .user-progress-bar {
  background-color: #fff;/*var(--learningBg);*/
  border-radius: 0.313rem;
}

.MyLearningCard .user-group .user-list .ant-avatar {
  border-width: 0.125rem;
}

.MyLearningCard .user-progress-bar .ant-progress-text {
  color: var(--color);
  font-family: "ff_bd";
  font-size: 1rem;
  position: relative;
  top: 0.25rem;
  cursor: default;
  margin-left: 1.25rem;
}

.MyLearningCard .user-progress-bar .ant-progress-bg {
  height: 0.25rem !important;
}

.MyLearningCard .user-progress-bar .ant-progress {
  display: flex;
}

.MyLearningCard .user-progress-bar {
  min-height: 6.875rem;
  display: flex;
  align-items: center;
}

.MyLearningCard .user-progress-bar.progress-mobile {
  min-height: 3.125rem;
  margin-top: 0.75rem;
}

.MyLearningCard .user-card .user-card-content {
  height: calc(100% - 9.25rem);
}

.ViewIndex .Learning .AllCourses svg [stroke="var(--color)"] {
  stroke: #fff;
}

.MyLearningCard .user-card .user-card-content .readmorereadless {
  display: none;
}

/* My Learning */
/* Bottom Navigation */
.BottomNavigation {
  display: none;
}

.BottomViewOn .BottomNavigation {
  display: block;
}

.BottomNavigation {
  position: fixed;
  left: calc(50% - 10rem);
  bottom: 0.85rem;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
}

.navigation-bar {
  border-radius: 0.188rem;
  padding: 0 1.5rem;
}

.navigation-bar .list-items {
  list-style: none;
  display: flex;
  position: relative;
  justify-content: center;
}

.navigation-bar .list-items .pointer {
  position: absolute;
  left: 0;
  height: 3.25rem;
  width: 3.25rem;
  z-index: 0;
  transition: all 0.2s linear;
}

.navigation-bar .list-items .pointer::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--navigationBg);
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 50%;
  box-shadow: 0 0.188rem 0.375rem rgba(0, 0, 0, 0.16);
}

.navigation-bar .list-items .pointer::before {
  border-radius: 50%;
}

.navigation-bar .list-items .item {
  flex: 1 1 0;
  position: relative;
  z-index: 2;
}

.navigation-bar .list-items .item .link {
  height: 3.25rem;
  width: 3.25rem;
  line-height: 4.5;
  text-align: center;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Close ICon */
.ViewHeader .Menubar .close-menu svg {
  height: 1rem;
}

.ViewHeader .navbar .navbar-right svg {
  height: 1.438rem;
}

/* Learning Detail */
.banner::before {
  background: transparent linear-gradient(270deg, #ffffff00 0%, #ff0000 100%) 0% 0% no-repeat padding-box;
  content: "";
  position: absolute;
  height: 100%;
  display: block;
  left: 0;
  width: 100%;
  opacity: 0.5;
}

.LearingDetail .banner {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
  overflow: hidden;
}

.bannerSearch {
  width: 6.25rem;
  height: 3.75rem;
  background: var(--navigationBg) 0% 0% no-repeat padding-box;
  border-radius: 0 28px 28px 0;
  opacity: 0.63;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10%;
}

.bannerSearch svg {
  position: relative;
  left: -0.313rem;
}
