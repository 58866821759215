.AdminLogin .AdminIndex .AdminHeader,
.AdminLogin .AdminIndex .NavigationBar {
  display: none !important;
}

.AdminLogin .AdminIndex .ContentView {
  margin-left: 0;
  padding-top: 0;
}

/* Login */
.AdminLogin {
  background: url("../../../../Assets/Images/Banner/Login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
}

.Login {
  width: 100%;
  height: 100vh !important;
}

.Login .AuthLogin {
  background-color: #fff;
  border-radius: 1.875rem;
  filter: drop-shadow(0 0.188rem 0.219rem rgba(0, 0, 0, 0.38));
  min-height: min(70vh, 37.5rem);
  margin: 3rem 0;
}

.Login .AuthLogin .logo svg [fill="var(--color)"] {
  fill: #000000;
}

.Login .AuthLogin .ant-btn {
  background-color: #398fe5;
  border-radius: 2rem;
  border: none;
  color: #fff;
  box-shadow: 0 0.188rem 0.219rem #c9c5c5;
}

.Login .AuthLogin .ant-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background-color: #398fe5;
}

.Login .AuthLogin a,
.Login .AuthLogin .SignUp {
  color: #777b86;
  font-size: 1rem;
}

.Login .AuthLogin a:hover {
  color: #777b86;
}

.Login .AuthLogin .SignUp a {
  color: #fd6a78;
}

.Login .AuthLogin .form-control {
  background: transparent;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0;
}

.Login .AuthLogin .backToLogin,
.Login .AuthLogin .backToLogin:hover {
  color: #398fe5;
}

@media screen and (max-width: 767.98px) {
  .Login .AuthLogin {
    max-width: calc(100vw - 1.5rem);
  }
}
