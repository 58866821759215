/* Header */
/* .ant-modal-root .ant-modal-wrap {
  z-index: 1052;
} */

.AdminHeader {
  background-color: var(--navigationBg);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 998;
}

.AdminHeader .logo {
  border-color: rgba(229, 229, 229, 0.5);
}

.AdminHeader .Search input,
.AdminHeader .Search .form-control:focus,
.AdminHeader .Search input::placeholder {
  color: var(--color);
}

.AdminHeader .navbar {
  padding: 0 2.313rem;
}

.AdminHeader .navbar .navbar-right {
  gap: 1.25rem;
}

.AdminHeader .user-avatar img {
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  border-radius: 50%;
  min-width: 3rem;
  min-height: 3rem;
}

.navbar .user-avatar {
  border-radius: 50%;
  border: 1px solid var(--color);
}

/* Navigation Bar */
.AdminIndex .NavigationBar {
  width: 17.5rem;
  background-color: var(--navigationBg);
  position: fixed;
  left: 0;
  padding-top: calc(6rem + 1.5rem);
  height: 100%;
}

.AdminIndex .NavigationBar-Sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}

.AdminIndex .NavigationBar-Sidenav .sb-sidenav-menu {
  flex-grow: 1;
}

.AdminIndex .NavigationBar-Sidenav .sb-sidenav-footer {
  flex-shrink: 0;
}

.AdminIndex .NavigationBar .NavigationLink {
  align-items: center;
  color: var(--navigationLink);
  height: 3.625rem;
  text-decoration: none !important;
}

.AdminIndex .NavigationBar .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.AdminIndex .NavigationBar .NavIconBG svg {
  width: 1.75rem;
}

.AdminIndex .NavigationBar .NavigationLink.active .NavIconBG svg [fill="var(--navigationLink)"],
.AdminIndex .NavigationBar .NavigationLink.active .NavIconBG svg [stroke="var(--navigationLink)"] {
  fill: var(--navigationLinkActive);
  stroke: var(--navigationLinkActive);
}

.AdminIndex .NavigationBar .NavigationLink.active .NavIconBG svg [fill="var(--color)"] {
  fill: var(--navigationLinkActive) !important;
}

.AdminIndex .NavigationBar .NavigationLink.active .NavIconBG svg [stroke="var(--color)"] {
  stroke: var(--navigationLinkActive) !important;
}

.AdminIndex .NavigationBar .NavigationLink.active .labelContainer {
  color: var(--navigationLinkActive);
}

.dark .AdminIndex .NavigationBar .NavigationLink.active {
  background: rgba(21, 21, 21, 0);
}

.light .AdminIndex .NavigationBar .NavigationLink.active {
  background: rgba(236, 243, 253, 0);
}

.AdminIndex .NavigationBar .nav-padding,
.AdminIndex .NavigationBar .NavigationLink {
  padding: 0 2.313rem;
}

.AdminIndex .NavIconBG .border-circle {
  width: 1.625rem;
  height: 1.625rem;
  border-width: 0.25rem;
  border-style: solid;
  border-radius: 50%;
}

.AdminIndex .NavIconBG .border-circle.orange {
  border-color: #dec514;
}

.AdminIndex .NavIconBG .border-circle.purple {
  border-color: #9783bd;
}

.AdminIndex .NavIconBG .border-circle.pink {
  border-color: #c454be;
}

.AdminIndex .navbar-group {
  max-height: calc(40vh - 1.875rem);
}

.AdminIndex .navbar-group .maxHeight {
  overflow-y: scroll;
  max-height: calc(100% - 3.75rem);
}

.AdminIndex .navbar-group > .NavigationLink {
  cursor: pointer;
}

.AdminIndex .AddGroup .NavigationLink .labelContainer {
  color: rgba(44, 44, 44, 0.5);
}

.dark .AdminIndex .AddGroup .NavigationLink .labelContainer {
  color: rgba(255, 255, 255, 0.5);
}

/* Sidebar */
.AdminHeader .Overlay {
  background-color: transparent;
  position: fixed;
  content: "";
  height: 100%;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  transition: all 0.5s ease;
  display: none;
  z-index: 7;
}

.dark .AdminHeader .Overlay,
.dark .ant-modal-root .ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.59);
}

.light .AdminHeader .Overlay,
.light .ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.15);
}

.AdminHeader .isActive.Overlay {
  display: block;
}

.AdminHeader .Menubar {
  background-color: var(--primary);
  padding: 0 1.781rem;
  position: fixed;
  right: -20rem;
  height: 100%;
  width: 20rem;
  top: 0;
  transition: all 0.5s ease;
  z-index: 9;
  display: flex;
  flex-shrink: 0;
  max-height: 100vh;
}

.AdminHeader .Menubar.isActive {
  right: 0rem;
}

.AdminHeader .Menubar .sb-sidenav {
  display: flex;
  flex-direction: column;
}

.AdminHeader .Menubar-Top-Content {
  overflow-y: auto;
}

.AdminHeader .Menubar .MenubarHeader {
  min-height: 5.75rem;
  position: relative;
  z-index: 9;
}

.AdminHeader .Menubar .Menubar-Items.NavigationBar {
  right: 0 !important;
  position: relative;
  background-color: transparent;
  top: 0;
  width: 100%;
  padding: 0;
  display: flex !important;
  overflow-y: auto;
  height: auto;
  flex-direction: column;
  flex-wrap: nowrap;
}

.AdminHeader .Menubar .Menubar-Items.NavigationBar .NavigationLink {
  padding: 0;
}

.AdminHeader .Menubar .MenubarMode {
  position: relative;
  margin-top: auto;
}

.AdminHeader .Menubar .MenubarMode .NavigationLink {
  color: var(--color);
  width: 100%;
}

.AdminHeader .Menubar .MenubarMode .NavIconBG {
  align-items: center;
  background: transparent;
  border-radius: 0.375rem;
  display: flex;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.AdminHeader .Menubar .NavigationLink .labelContainer {
  font-family: ff_md;
  font-size: 1rem;
  margin-left: 0.625rem;
  width: 100%;
}

.AdminHeader .Menubar .close-menu {
  position: fixed;
  right: 1rem;
  top: 1rem;
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease;
}

.AdminHeader .Menubar.isActive .close-menu {
  opacity: 1;
  transform: scale(1);
}

.AdminHeader .Menubar .close-menu svg {
  height: 1rem;
}

.AdminHeader .navbar .navbar-right svg {
  height: 1.438rem;
}

.AdminIndex .AdminHeader .Menubar .NavigationBar .NavigationLink.active {
  background-color: transparent;
}

.AdminIndex .AdminHeader .Menubar .NavigationLink.active .NavIconBG [fill="var(--color)"] {
  fill: var(--navigationLinkActive);
}

.AdminIndex .AdminHeader .Menubar .NavigationLink.active .NavIconBG [stroke="var(--color)"] {
  stroke: var(--navigationLinkActive);
}

/* View */
.AdminIndex .ContentView {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Learning */
.AdminIndex .Learning .AddContents,
.addBtnOrange {
  background-color: #0190e9;
}

.AdminIndex .addSamplebtn {
  background-color: transparent;
  color: var(--color);
  text-decoration: underline;
}

.AdminIndex .addBulkbtn {
  background-color: #000;
}

.AdminIndex .Learning .AllContent {
  border-radius: 0.625rem 0.938rem;
}

.AdminIndex .Learning .AllContent .antdTable img {
  border-radius: 0.625rem;
}

.AdminIndex .Learning .AllContent .antdTable .ant-table-scroll-horizontal,
.AdminIndex .Learning .AllContent .antdTable .ant-table-scroll-horizontal * {
  color: var(--color) !important;
}
.AdminIndex .Learning .AllContent .antdTable .ant-table-scroll-horizontal .readmorereadless,
.readmorereadless {
  color: var(--navigationLinkActive) !important;
}

.dark .ant-table-wrapper .ant-table-scroll-horizontal .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: rgba(21, 21, 21, 0.5);
  color: var(--navigationLinkActive) !important;
}

.light .ant-table-wrapper .ant-table-scroll-horizontal .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover {
  background: rgba(236, 243, 253, 0.5);
  color: var(--navigationLinkActive) !important;
}

.AdminIndex .Learning .AllContent .antdTable .action-delete svg {
  height: 1.375rem;
}

.AdminIndex .Learning .AllContent .antdTable .action-edit svg {
  height: 1.375rem;
}

.AdminIndex .Learning .AllContent .antdTable .tableImage {
  max-height: 6.25rem;
  object-fit: cover;
}

.AdminIndex .Learning .AllContent .antdTable .DragIcon {
  transform: scale(1.4);
}
/* LearningDetail */
.AdminIndex .LearningDetail .Learning-Image img {
  min-width: 18.75rem;
  border-radius: 0.625rem;
  height: 8.75rem;
  width: 100%;
  object-fit: cover;
  outline: 1px solid rgba(229, 229, 229, 0.15);
}

.AdminIndex .LearningDetail .AllContent {
  border-radius: 0.625rem 0.938rem;
}

.AdminIndex .LearningDetail .AllContent .antdTable .ant-table-scroll-horizontal,
.AdminIndex .LearningDetail .AllContent .antdTable .ant-table-scroll-horizontal * {
  color: var(--color) !important;
}

.AdminIndex .LearningDetail .AllContent .antdTable .action-delete svg {
  height: 1.375rem;
}

.AdminIndex .LearningDetail .AllContent .antdTable .action-edit svg {
  height: 1.375rem;
}

/* LearningDetail */

/* EditLearning */
.AdminIndex .EditLearning .AllContent {
  border-radius: 0.625rem 0.938rem;
}

.AdminIndex .EditLearning .CourseImage {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 17.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
}

.AdminIndex .EditLearning .CourseImage:before {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 0.32;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.AdminIndex .EditLearning .replaceImage svg {
  height: 2rem;
}

.AdminIndex .EditLearning .form-control {
  background: transparent;
  background-color: transparent !important;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  color: var(--color);
  font-family: "ff_bd";
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-style: italic;
}

.AdminIndex .EditLearning .nameEdit #iconContainer {
  right: 0;
}

.AdminIndex .EditLearning .nameEdit svg {
  height: 1.25rem;
}

.AdminIndex .EditLearning .not-editable .form-control {
  border-color: transparent;
}

.AdminIndex .EditLearning .editable textarea.form-control {
  height: auto;
  max-height: 9rem;
  overflow-y: auto;
  resize: none;
  line-height: normal;
}

.AdminIndex .EditLearning .Rewards .CategoryAdd {
  width: fit-content;
}

.editButton {
  border-radius: 0.625rem;
  border-color: transparent !important;
  color: #fff;
}

.editButton:hover {
  color: #fff !important;
}

.editButton.Cancel {
  background-color: #3e42b0;
}
.editButton.Continue {
  background-color: #3bccf6;
}

.ant-table-wrapper .ant-table-thead > tr > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

/* EditLearning */
/* Drag On */
.DragOn .dzu-dropzone {
  display: block;
  border: none;
  border-radius: 0;
  min-height: 100%;
  overflow: auto;
}

.DragOn .dzu-dropzoneActive .Learning {
  position: relative;
  z-index: -1;
}

.DragOn .dzu-dropzoneActive {
  background-color: rgba(0, 0, 0, 0.85);
}

.DragOnDisplay {
  position: fixed;
  width: calc(100% - 17.5rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(96px / 2);
  right: 0;
  transform: translateY(100%);
  opacity: 0.5;
  transition: all 0.5s ease-in;
}

.DragOn .dzu-dropzoneActive .DragOnDisplay {
  transform: translateY(0);
  opacity: 1;
}

.DragOn .antdProgess {
  position: fixed;
  width: calc(100% - 17.5rem);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(96px / 2);
  right: 0;
  transform: translateY(100%);
  opacity: 0.5;
  transition: all 0.5s ease-in;
}

.DragOn .activeProgess.antdProgess {
  transform: translateY(0);
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1;
}

.DragOn .antdProgess .ant-progress-text {
  display: block;
  color: #fff;
  width: 100%;
  font-size: 2rem;
  margin-top: 2rem;
  text-align: center;
  font-family: "ff_bd";
}
/* Drag On */

/* Add Learning COurse */
.AdminIndex .EditLearning .CourseDescription .form-control {
  min-height: 6.25rem;
  resize: none;
}

.GridSelect label.d-block .uploadedImageListItems {
  border: 1px solid transparent;
  border-radius: 0.5rem;
  transition: all 0.5s ease;
  min-width: 6.25rem;
}

.GridSelect label.d-block.selected .uploadedImageListItems {
  border: 1px solid var(--navigationBg);
}

.light .GridSelect label.d-block.selected .uploadedImageListItems {
  border: 1.5px solid var(--navigationLinkActive);
}

.light .GridSelect label.d-block .uploadedImageListItems {
  border: 1.5px solid rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
}

.BadgeItem img {
  height: 2.25rem;
  width: 2.25rem;
  margin-right: 0.5rem;
  object-fit: cover;
}

/* Basic Antd Table */
.ant-table-wrapper .ant-table-tbody > tr:last-child > td {
  border-bottom: 0 !important;
}
.antdTableNormal {
}

.antdTableNormal .ant-table-thead *,
.antdTableNormal .ant-table-tbody {
  color: var(--color) !important;
}

.AdminIndex .antdTableNormal .action-edit svg {
  height: 1.75rem;
}

.downloadBulk > a,
.downloadBulk {
  width: 2.75rem;
}

.AdminIndex .antdTableNormal .action-edit .d-inline-block {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.badgeImage {
  height: 3rem;
  object-fit: cover;
}

/* Admin Profile */
/* Admin Profile Page */
.uploadProfile .customerProfileAvatarBox {
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
}

.uploadProfile .customerProfileAvatarImage {
  align-items: center;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  font-size: 1.25rem;
  height: 180px;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  width: 180px;
}

.uploadProfile .customerProfileAvatarImage img {
  color: transparent;
  height: 100%;
  text-align: center;
  text-indent: 10000px;
  width: 100%;
  object-fit: cover;
}

.uploadProfile .customerProfileAvatarImage.is-active img {
  border: 1px solid var(--navigationLink);
  border-radius: 50%;
  object-fit: cover;
}

.uploadProfile .customerProfileAvatarChange {
  align-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  bottom: 14%;
  box-shadow: 0 6px 5px 0 rgba(52, 58, 63, 0.09);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.75rem;
  font-weight: 500;
  height: 42px;
  width: 42px;
  justify-content: center;
  line-height: 1;
  min-width: 42px;
  position: absolute;
  right: 14%;
  -webkit-transform: scale(1) translate(50%, 50%);
  transform: scale(1) translate(50%, 50%);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
}

.uploadProfile .customerProfileUpload .ant-upload-list {
  display: none;
}

.Profile .saveChanges:hover,
.Profile .saveChanges:focus {
  color: inherit !important;
  border-color: transparent !important;
}

.Profile .ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
  color: inherit !important;
  border-color: transparent !important;
}

/* Admin Profile Page End*/
/* Table */
.antdTableNormal .dataIcon {
  height: 3.75rem;
  width: 3.75rem;
  border: 1px solid var(--color);
  border-radius: 0.5rem;
}

/* All Category */
.antdSelect {
  font-family: "ff_bd";
}

.antdSelect.allCourses .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.ant-select-selection-placeholder {
  color: var(--color) !important;
}

.antdSelect .ant-select-selection-item {
  max-width: 8rem !important;
}

.ant-select-dropdown {
  font-family: "ff_rg" !important;
}

.dark .antdSelect .ant-select-selection-placeholder {
  color: #000 !important;
}

/* Learning */
.toggleMenuCategory {
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 10px);
  cursor: pointer;
}

.toggleMenuCategory svg {
  height: 1.1rem;
}

.dropdown-category-item {
  background-color: #fff;
  border-radius: 0.5rem;
  min-width: 76px;
  z-index: 9;
  right: 0.5rem;
  text-align: end;
  top: calc(100% + 0.5rem);
}

.light .dropdown-category-item {
  background-color: #000;
}

.dropdown-category-item * {
  color: #fff !important;
}

.dark .dropdown-category-item * {
  color: #000 !important;
}

.dark .antdModal .dropdown-category-item,
.light .antdModal .dropdown-category-item {
  background-color: #000 !important;
}

.dark .antdModal .dropdown-category-item * {
  color: #fff !important;
}

/* Dropdown bugs */
.learner-dropdown {
  z-index: 996;
}

/* Loader */
.CourseImageUploader.loadingFile .ant-upload-select > span {
  display: grid !important;
  place-items: center;
}

.dark .spinner-border {
  color: var(--color);
}

/* Sub Admin */
.SubAdmin .Learning-Content .name {
  display: none;
}
