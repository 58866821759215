@media screen and (min-width: 768px) {
  .CourseDetailImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .CourseDetailImage {
    max-width: 20rem;
    width: 100%;
  }

  .antdModal.CourseDetail {
    border-radius: 1rem;
  }

  .antdModal.CourseDetail .user-progress-bar.col-9 {
    height: 2rem;
    display: flex !important;
    align-items: center;
  }
}

@media screen and (max-width: 767.98px) {
  .antdModal.CourseDetail {
    width: 100vw !important;
    max-width: 100vw;
    top: 0;
    position: fixed;
    left: 0;
  }

  .antdModal.CourseDetail .ant-modal-content {
    height: 100vh;
    border-radius: 0;
    max-height: unset !important;
    overflow-y: auto !important;
  }

  .antdModal.CourseDetail .ant-modal-content .CourseDetailImage img {
    object-fit: cover;
  }

  .antdModal.CourseDetail .CourseDetailDescription {
    border-radius: 26px;
    position: relative;
    top: -50px;
    background-color: var(--navigationBg);
  }

  .antdModal.CourseDetail .user-list {
    position: absolute;
    top: -1.4rem;
    right: 1.5rem;
  }

  .antdModal.CourseDetail .user-list.ant-avatar-group > *:not(:first-child) {
    margin-inline-start: -25px;
  }
}
