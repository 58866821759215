@media screen and (max-width: 1023.98px) {
  .ViewHeader {
    border-radius: 0 0 1.5rem 1.5rem;
    height: 5.75rem;
    padding: 0 1.5rem;
  }

  .ViewHeader .logo svg {
    max-width: 9.375rem;
  }

  .ViewHeader .navbar {
    padding: 0;
  }

  .ViewHeader .headerMobile {
    display: block;
  }

  .ViewHeader .headerDesktop {
    display: none;
  }

  /* View */
  .ViewIndex .ContentView {
    padding-top: 5.75rem;
  }

  .ViewIndex .ContentView .Learning {
    padding: 0 1.5rem;
    padding-right: 0;
  }

  /* Navigation */
  .ViewIndex .NavigationBar {
    display: none !important;
  }
  /* Navigation */

  /* My Learning */
  .myLearningList {
    display: flex;
    overflow-x: scroll;
    gap: 1.25rem;
  }

  .myLearningList .MyLearningCard:last-child {
    margin-right: 1.25rem;
  }

  /* Dashboard */
  .dashboardMobileView {
    padding: 0 0 0 1.5rem;
    padding-top: 1.5rem;
  }

  .dashboardMobileView .CourseCard {
    width: 100%;
    min-width: 15.625rem;
  }

  .CourseCard-Item,
  .CoursesCardMobile-Items,
  .ArchivementMobile-Item {
    display: flex;
    gap: 0.938rem;
    overflow-x: scroll;
    margin-bottom: 0.938rem;
  }

  .CourseCard-Item .labelOfCourse,
  .ArchivementMobile-Item .f14 {
    font-size: 0.813rem;
  }

  .CoursesCardMobile {
    width: 100%;
    min-width: 17.5rem;
  }

  .CoursesCardMobile img {
    border-radius: 1rem;
    height: 8.688rem;
    object-fit: cover;
  }

  .CoursesCardMobile .CoursesCardMobile-Content {
    top: 0;
    color: #fff;
  }

  .CoursesCardMobile-Overlay::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .ArchivementMobile-Item .c-active {
    border-radius: 1rem;
  }

  .ArchivementMobile-Item .c-active ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-bottom: 0;
    place-items: center;
    max-width: 85px;
    margin-left: auto;
  }

  .ArchivementMobile-Item .ArchivementMobile {
    width: 100%;
    min-width: 17.5rem;
  }

  .dashboardMobileView .CourseCard:last-child,
  .CoursesCardMobile-Items .CoursesCardMobile:last-child,
  .ArchivementMobile-Item .ArchivementMobile:last-child {
    margin-right: 1.25rem;
  }
}

@media screen and (max-width: 767.98px) {
  /* Header */
  .ViewIndex .ViewHeader {
    height: 3.875rem;
  }
  /* Header */

  /* View */
  .ViewIndex .ContentView {
    padding-top: 3.875rem;
  }
  /* View */

  /* Learning Detail */
  .MyLearningCard .user-card .user-card-content h3,
  .MyLearningCard .user-progress-bar .ant-progress-text {
    font-size: 0.875rem;
  }

  .bannerSearch {
    width: 4.25rem;
    height: 2.75rem;
  }

  .bannerSearch svg {
    height: 1.825rem;
  }
}

@media screen and (max-width: 575px) {
  .ViewHeader .logo svg {
    max-width: 6.875rem;
  }

  .ViewHeader .navigation-bar {
    padding: 0 0.5rem;
  }
}

@media screen and (min-width: 600px) {
  .ViewHeader .BottomNavigation {
    max-width: 35.938rem;
    left: calc(50% - 17.969rem);
  }
}

@media screen and (min-width: 1024px) {
  /* Header */
  .ViewHeader .logo {
    min-width: 16.25rem;
    border-right: 0.063rem solid rgba(229, 229, 229, 0.2);
    border-bottom: 0.063rem solid rgba(229, 229, 229, 0.2);
    height: 6rem;
  }

  .ViewHeader .headerMobile {
    display: none;
  }

  .ViewHeader .headerDesktop {
    display: block;
  }
  /* Header */

  /* Navbar */
  .ViewIndex .NavigationBar {
    width: 16.25rem;
  }
  /* Navbar */

  /* View */
  .ViewIndex .ContentView {
    margin-left: 16.25rem;
  }

  .ViewIndex .ContentView {
    padding-top: 5.75rem;
  }

  .ViewIndex .ContentView .Learning {
    padding: 0 2.313rem;
  }
  /* View */

  /* Learning */
  .Learning .myLearningItemsGrid,
  .Dashboard .dashboardCourses {
    display: grid;
    gap: 2.875rem 1.875rem;
  }

  .Dashboard .dashboardCourses {
    gap: 1.5rem 0;
  }
}

@media screen and (min-width: 1200px) {
  .ViewHeader .logo {
    min-width: 17.5rem;
  }

  .ViewIndex .NavigationBar {
    width: 17.5rem;
  }

  .ViewIndex .ContentView {
    margin-left: 17.5rem;
  }
}

@media screen and (min-width: 1400px) {
  /* Dashboard */
  .Dashboard .DashboardDetail {
    max-width: 90rem;
    margin: 0 auto;
  }

  .Archivement .Reward {
    border-right: 0.063rem solid var(--color);
  }
}

@media screen and (min-width: 1600px) {
  /* Dashboard */
  .Dashboard .dashboardCourses {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1800px) {
  .Learning .myLearningItemsGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 1198.98px) and (min-width: 768px) {
  /* Learning Detail */
  .bannerSearch {
    width: 4.25rem;
    height: 2.75rem;
  }

  .bannerSearch svg {
    height: 1.825rem;
  }
}

@media screen and (max-width: 1023.98px) and (min-width: 768px) {
  .ViewHeader {
    height: 5.75rem;
  }

  .ViewIndex .ContentView {
    padding-top: 5.75rem;
  }
}

@media screen and (max-width: 1199.98px) and (min-width: 991px) {
  /* Dashboard */
  .DashboardTab .f24 {
    font-size: 1rem;
  }

  .CoursesCard .f24 {
    font-size: 1.25rem;
  }

  .Dashboard .dashboardCourses {
    gap: 1.5rem 0;
  }
}

@media screen and (max-width: 1199.98px) and (min-width: 1024px) {
  /* My Learning */
  .Learning .myLearningItemsGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Dashboard */
  .Dashboard .dashboardCourses {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1399.98px) and (min-width: 1200px) {
  /* My Learning */
  .Learning .myLearningItemsGrid {
    grid-template-columns: repeat(4, 1fr);
  }

  .MyLearningCard .user-card .user-image {
    height: 13.25rem;
  }

  .MyLearningCard .user-card .user-card-content {
    height: calc(100% - 13.25rem);
  }

  /* Dashboard */
  .Dashboard .DashboardDetail {
    padding: 0 2.188rem;
  }

  .Dashboard .dashboardCourses {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1799.98px) and (min-width: 1400px) {
  /* My Learning */
  .Learning .myLearningItemsGrid {
    grid-template-columns: repeat(4, 1fr);
  }

  /* Dashboard */
  .Dashboard .dashboardCourses {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1439.98px) and (min-width: 1024px) {
  /* Header */
  .ViewHeader {
    height: 4.375rem;
  }

  .ViewHeader .logo {
    height: 4.375rem;
  }

  .ViewHeader .navbar .navbar-right {
    transform: scale(0.8);
  }

  .ViewHeader .logo svg {
    height: 1.875rem;
  }
  /* Header */

  /* Navbar */
  .ViewIndex .NavigationBar .NavIconBG svg {
    height: 1.375rem;
  }

  .ViewIndex .NavigationBar {
    padding-top: calc(4.375rem + 1.5rem);
  }

  .ViewIndex .NavigationBar .NavigationLink .labelContainer,
  .ViewIndex .NavigationBar .navbar-title {
    font-size: 0.875rem;
  }

  .NavigationBar .NavigationLink {
    height: 3rem;
  }
  /* Navbar */

  /* Sidebar */
  .ViewHeader .Menubar .labelContainer svg {
    height: 1.375rem;
  }
  /* Sidebar */

  /* View */
  .ViewIndex .ContentView {
    padding-top: 4.375rem;
  }
  /* View */

  /* Learning */
  .AllCourses,
  .MasterData .RadioButtonSelectAnyOne {
    height: 3rem !important;
  }

  .AllCourses p {
    font-size: 0.875rem;
  }

  .MyLearningCard .user-card .user-card-content h3 {
    font-size: 1.125rem;
  }
  /* Learning */
}
