@media screen and (max-width: 1023.98px) {
  .AdminHeader {
    border-radius: 0 0 1.5rem 1.5rem;
    height: 5.75rem;
    padding: 0 1.5rem;
  }

  .AdminHeader .logo svg {
    max-width: 9.375rem;
  }

  .AdminHeader .navbar {
    padding: 0;
  }

  .AdminHeader .headerMobile {
    display: block;
  }

  .AdminHeader .headerDesktop {
    display: none;
  }

  /* View */
  .AdminIndex .ContentView {
    padding-top: 5.75rem;
  }

  .AdminIndex .ContentView .Learning {
    padding: 0 1.5rem;
  }

  .AdminIndex .Learning {
    padding-bottom: 50px !important;
  }

  .AdminIndex .Learning .AllContent {
    padding: 1.5rem !important;
  }
  /* Navigation */
  .AdminIndex .NavigationBar {
    display: none !important;
  }
  /* Navigation */

  /* Learning Detail */
  .AdminIndex .ContentView .LearningDetail {
    padding: 0 1.5rem;
  }
}

@media screen and (max-width: 767.98px) {
  /* Header */
  .AdminIndex .AdminHeader {
    height: 3.875rem;
  }
  /* Header */

  /* View */
  .AdminIndex .ContentView {
    padding-top: 3.875rem;
  }
  /* View */
}

@media screen and (max-width: 575px) {
  .AdminHeader .logo svg {
    max-width: 6.875rem;
  }

  .AdminHeader .navigation-bar {
    padding: 0 0.5rem;
  }

  /* Learning */
  .AdminIndex .LearningDetail .Learning-Content {
    text-align: center;
    width: 100%;
  }

  .AdminIndex .LearningDetail .f24 {
    font-size: 1.25rem;
  }

  .AdminIndex .LearningDetail .f18 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .AdminHeader .BottomNavigation {
    max-width: 35.938rem;
    left: calc(50% - 17.969rem);
  }
}

@media screen and (min-width: 1024px) {
  /* Header */
  .AdminHeader .logo {
    min-width: 16.25rem;
    border-right: 0.063rem solid rgba(229, 229, 229, 0.2);
    border-bottom: 0.063rem solid rgba(229, 229, 229, 0.2);
    height: 6rem;
  }

  .AdminHeader .headerMobile {
    display: none;
  }

  .AdminHeader .headerDesktop {
    display: block;
  }
  /* Header */

  /* Navbar */
  .AdminIndex .NavigationBar {
    width: 16.25rem;
  }
  /* Navbar */

  /* View */
  .AdminIndex .ContentView {
    margin-left: 16.25rem;
    padding-top: 5.75rem;
  }
  /* View */

  /* Learning */
  .AdminIndex .ContentView .Learning {
    padding: 0 2.313rem;
  }

  /* Learning Detail */
  .AdminIndex .ContentView .LearningDetail {
    padding: 0 2.313rem;
  }
}

@media screen and (min-width: 1200px) {
  /* Header */
  .AdminHeader .logo {
    min-width: 17.5rem;
  }
  /* Header */

  /* Navbar */
  .AdminIndex .NavigationBar {
    width: 17.5rem;
  }
  /* Navbar */

  /* View */
  .AdminIndex .ContentView {
    margin-left: 17.5rem;
  }
  /* View */
}

@media screen and (max-width: 1023.98px) and (min-width: 768px) {
  /* Header */
  .AdminHeader {
    height: 5.75rem;
  }
  /* Header */

  /* View */
  .AdminIndex .ContentView {
    padding-top: 5.75rem;
  }
  /* View */
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  #iconContainer {
    top: calc(50% - 1rem) !important;
  }
}

@media screen and (max-width: 1439.98px) and (min-width: 1024px) {
  /* Header */
  .AdminHeader {
    height: 4.375rem;
  }

  .AdminHeader .logo {
    height: 4.375rem;
  }

  .AdminHeader .navbar .navbar-right {
    transform: scale(0.8);
  }

  .AdminHeader .logo svg {
    height: 1.875rem;
  }
  /* Header */

  /* Navbar */
  /* .AdminIndex .NavigationBar .NavIconBG svg {
    height: 1.375rem; 
  } */

  .AdminIndex .NavigationBar .NavIconBG svg {
    width: 1.75rem;
  }

  .AdminIndex .NavigationBar {
    padding-top: calc(4.375rem + 1.5rem);
  }

  .AdminIndex .NavigationBar .NavigationLink .labelContainer,
  .AdminIndex .NavigationBar .navbar-title {
    font-size: 0.875rem;
  }

  .AdminIndex .NavigationBar .NavigationLink {
    height: 3rem;
  }
  /* Navbar */

  /* Sidebar */
  .AdminHeader .Menubar .labelContainer svg {
    height: 1.375rem;
  }
  /* Sidebar */

  /* View */
  .AdminIndex .ContentView {
    padding-top: 4.375rem;
  }
  /* View */
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .ForgotPassword #iconContainer {
    top: calc(50% - 1rem);
  }
  .ForgotPassword #iconContainer svg {
  }
}
