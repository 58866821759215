@media (min-width: 1200px) {
  html {
    font-size: 60%;
  }
}
@media (min-width: 1300px) {
  html {
    font-size: 70%;
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 90%;
  }
}
@media (min-width: 1800px) {
  html {
    font-size: 100%;
  }
}

body,
.ant-app,
#root {
  font-family: "ff_rg", Arial, Helvetica, sans-serif;
  color: var(--black);
  font-style: italic;
}
/* Color scheme of the project Approved Date: Friday, 27 Sep 2023 */
:root.light {
  --primary: #eddca6;
  --color: #000000;

  /* Navigation Bar */
  --navigationBg: #fff4ca;
  --navigationLink: #000;
  --navigationLinkActive: #fc5007;

  /* My Learning */
  --learningBg: #293641;

  /* --b-shadow: 0 0.25rem 1.125rem 0 var(--navigationLink); */
  --b-shadow: var(--navigationBg) 0 0.025rem 1.125rem 0;

  --error: #d10f0f;
  --green: #41be73;
}

:root.dark {
  --primary: #1b2932;
  --color: #fff;

  /* Navigation Bar */
  --navigationBg: #2a3742;
  --navigationLink: #fff;
  --navigationLinkActive: #3BCCF6;

  /* My Learning */
  --learningBg: #FFFfff;

  /* --b-shadow: 0 0.25rem 1.125rem 0 var(--navigationLink); */
  --b-shadow: var(--navigationBg) 0 0.025rem 1.125rem 0;

  --error: #d10f0f;
  --green: #41be73;
}

/* Fixing CSS Default CSS*/
p {
  margin-bottom: 0;
}

img {
  width: 100%;
}
/* Fixing CSS Default CSS*/

.txt-primart {
  color: var(--primary) !important;
}

.txt-white {
  color: var(--color) !important;
}

.txt-link {
  color: var(--navigationLinkActive) !important;
}

.txt-error {
  color: var(--error) !important;
}

.c-active {
  background-color: var(--navigationBg) !important;
}

.c-error {
  background-color: var(--error) !important;
}

.c-sky {
  background-color: var(--navigationLinkActive) !important;
}

/* Font Family */
.ff_rg {
  font-family: "ff_rg" !important;
}

.ff_md {
  font-family: "ff_md" !important;
}

.ff_bd {
  font-family: "ff_bd" !important;
}

/* Font Size */
.f10 {
  font-size: 0.625rem;
}

.f12 {
  font-size: 0.75rem;
}

.f14 {
  font-size: 0.875rem;
}

.f16 {
  font-size: 1rem;
}

.f18 {
  font-size: 1.125rem;
}

.f20 {
  font-size: 1.25rem;
}

.f22 {
  font-size: 1.375rem;
}

.f24 {
  font-size: 1.5rem;
}

.f32 {
  font-size: 2rem;
}

.f40 {
  font-size: 2.5rem;
}

/* Comman CSS */
/* Height - Min Height */
.vh-100 {
  height: 100vh;
}

.h24 {
  height: 1.5rem;
}

.h30 {
  height: 1.875rem;
}

.h38 {
  height: 2.375rem;
}

.h46 {
  height: 2.75rem;
}

.h50 {
  height: 3.125rem;
}

.h70 {
  height: 4.375rem;
}

.lh50 {
  line-height: 3.125rem !important;
}

.line-height-normal {
  line-height: normal;
}

/* Width - Min Width */
.min-fit-content {
  min-width: fit-content;
}
/* Comman CSS End*/

/* Cursor */
.cursor-pointer-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

/* Scroll */
::-webkit-scrollbar {
  border-radius: 0.375rem;
  width: 0.375rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 0.375rem;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 var(--light-gray);
}

/* Opacity */
.opacity-0 {
  opacity: 0%;
}

.opacity-25 {
  opacity: 25%;
}

.opacity-50 {
  opacity: 50%;
}

.opacity-75 {
  opacity: 75%;
}

.opacity-100 {
  opacity: 100%;
}

/* Input Field */
.input-box-shadow {
  box-shadow: 0 0.125rem 0.375rem 0 #0187cf33;
}

.focus-border:focus {
  border: 0.063rem solid var(--primary);
  box-shadow: none;
}

.rounded-8 {
  border-radius: 0.5rem;
}

/* custom Padding */
.px-12 {
  padding: 0 12px !important;
}

.b-shadow {
  box-shadow: var(--b-shadow) !important;
}

.spinner-border {
  border-color: var(--color);
  border-right-color: transparent;
}

.bg-danger {
  background-color: var(--error) !important;
}

.min-width-120 {
  min-width: 7.5rem !important;
}
